<!-- <mat-card class="card-section" *ngIf="!loginDisplay">
    
</mat-card> -->

<div *ngIf="loginDisplay" class="protected-section">
    <div class="starting-section">
        <form [formGroup]="form" class="employee-select-form">
            <div class="employee-select-form-content">
                <!-- auto-completion for first name -->
                <mat-form-field appearance="standard">
                    <mat-label for="firstName">First Name</mat-label>
                    <input matInput type="search" formControlName="firstName" name="firstName" [matAutocomplete]="autoFirstName"/>
                    <mat-autocomplete #autoFirstName="matAutocomplete" panelWidth="auto" (optionSelected)="employeeSelected($event)">
                        <mat-option *ngFor="let filteredEmployee of filteredEmployees" [value]="filteredEmployee">
                            {{filteredEmployee.firstName + " " + filteredEmployee.lastName + ": " +filteredEmployee.workEmail}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                
                <mat-form-field appearance="standard">
                    <mat-label for="lastName">Last Name</mat-label>
                    <input matInput type="search" formControlName="lastName" name="lastName" [matAutocomplete]="autoLastName"/>
                    <mat-autocomplete #autoLastName="matAutocomplete" panelWidth="auto" (optionSelected)="employeeSelected($event)">
                        <mat-option *ngFor="let filteredEmployee of filteredEmployees" [value]="filteredEmployee">
                            {{filteredEmployee.firstName + " " + filteredEmployee.lastName + ": " +filteredEmployee.workEmail}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <!-- auto-completion for work email -->
                <mat-form-field appearance="standard">
                    <mat-label for="workEmail">Work Email</mat-label>
                    <input matInput type="search" formControlName="workEmail" name="workEmail" [matAutocomplete]="autoWorkEmail" />
                    <mat-autocomplete #autoWorkEmail="matAutocomplete" panelWidth="auto" (optionSelected)="employeeSelected($event)">
                        <mat-option *ngFor="let filteredEmployee of filteredEmployees" [value]="filteredEmployee">
                            {{filteredEmployee.workEmail}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                
                <!-- auto-completion for district -->
                <mat-form-field appearance="standard">
                    <mat-label for="distrcit">District Number</mat-label>
                    <input matInput readonly type="text" formControlName="districtNumber" name="districtNumber" />
                </mat-form-field>

                <!-- district name from the district number look up-->
                <mat-form-field appearance="standard">
                    <mat-label for="district">District</mat-label>
                    <input matInput readonly type="text" formControlName="district" name="district" />
                </mat-form-field>
            </div>
            <button class="employee-select-form-button" (click)="clearEmployeeSelected()">
                <mat-icon>clear_all</mat-icon> Clear All
            </button>
            
            <!-- TODO: Default to the value from integraion needs to figure out what to do -->
            <!-- <mat-form-field appearance="standard">
                <mat-label>Personal Consent</mat-label>
                
                <input matInput readonly formControlName="personalConsent" name="personalConsent"/>
            </mat-form-field> -->
        </form>

        <br />
        <br />

        <div class="editor">
            <!-- row -->
            <div class="photo-widget">
                <!-- column 1 - image cropper -->

                <!-- source: https://github.com/Mawi137/ngx-image-cropper -->
                <!-- display the gray box when photo is not selected -->
                <div 
                    [ngClass]="{'photo-cropper-container': !isImageLoaded, 'photo-cropper-container-no-pointer': isImageLoaded}"
                    matRipple="!isImageLoaded" [matRippleDisabled]="isImageLoaded" [matRippleCentered]="true"
                    (click)="!isImageLoaded && fileUpload(fileInput)">
                    <div *ngIf="!isImageLoaded">
                        <p class="cropper-prompt-text-header">
                            <mat-icon class="chevron-left delay">chevron_right</mat-icon>
                            <mat-icon class="chevron-left">chevron_right</mat-icon>
                            CLICK HERE
                            <mat-icon class="chevron-right">chevron_left</mat-icon>
                            <mat-icon class="chevron-right delay">chevron_left</mat-icon>
                        </p>
                        <p>
                            To Select A Photo
                        </p>
                    </div>
                    

                    <image-cropper class="cropper" [imageChangedEvent]="imageChangedEvent" [imageFile]="imageFile"
                        [maintainAspectRatio]="true" format="jpeg" [alignImage]="'center'" [transform]="transform"
                        [style.display]="isImageLoaded ? 'flex' : 'none'"
                        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
                        (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
                    </image-cropper>
                </div>
                <!-- column 2 preview -->
                <div class="photo-utility-container">
                    <div class="preview-container">
                        <img [src]="croppedImage" />
                    </div>
                    <div class="current-employee-card-container">
                        <div class="current-employee-data">{{currentEmployee.fullName}}</div>
                        <div class="current-employee-data">{{currentEmployee.workEmail}}</div>
                    </div>
                    <div class="photo-tool-bar-container">
                        <div class="photo-tool-bar-row">
                            <button class="action-bar-button" (click)="imageRotate()">
                                <mat-icon>rotate_left</mat-icon> Rotate
                            </button>
                        </div>
                        <div class="photo-tool-bar-row">
                            <button class="action-bar-button" (click)="fileClear()">
                                <mat-icon>clear</mat-icon> Clear
                            </button>
                        </div>
                        <div class="photo-tool-bar-row">
                            <button class="action-bar-button" (click)="uploadImage()">
                                <mat-icon>cloud_upload</mat-icon> <span>Upload</span> 
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <input type="file" (change)="fileChangeEvent($event)" (click)="fileUploadOnClick($event)" #fileInput
                id="file-input" />

            <br />
        </div>
    </div>



    <!-- <button mat-fab color="link" class="tool-bar-button" (click)="loadImageFailed()">
        <mat-icon>error_outline</mat-icon>
    </button>

    <button mat-fab color="link" class="tool-bar-button" (click)="openUploadingDialog()">
        <mat-icon>notifications</mat-icon>
    </button> 

    <button mat-fab color="link" class="tool-bar-button" (click)="openSuccessDialog()">
        <mat-icon>notifications</mat-icon>
    </button>  -->
    
</div>
